import React from 'react';
import { LoggerService } from '@magnolia/template-annotations';
import constants from './constants';
import DefaultFallbackComponent from './DefaultFallbackComponent';

function componentHelper() {
  return {
    getRenderedComponent, getComponentProperties, addComment, classnames, removeCommentElement, buildKey
  };

  function getRenderedComponent(componentContent, componentMappings, fallbackComponentConfig, index = 0) {
    const emptyComponent = React.createElement('div');
    if (!componentContent) {
      const msg = 'Component content is missing';
      LoggerService.error(msg);
      return emptyComponent;
    }
    if (!componentMappings) {
      const msg = 'Component mappings is missing';
      LoggerService.error(msg);
      return emptyComponent;
    }

    let componentClass = componentMappings[componentContent[constants.TEMPLATE_ID_PROP]];
    let isFallbackComponent = false;
    if (!componentClass) {
      const msg = `Component with ID ${componentContent[constants.TEMPLATE_ID_PROP]} is not mapped.`;
      LoggerService.error(msg);
      const fallbackComponent = getFallbackComponent(fallbackComponentConfig);
      if (!fallbackComponent) {
        return emptyComponent;
      }
      componentClass = fallbackComponent;
      isFallbackComponent = true;
    }
    return React.createElement(componentClass, getComponentProperties(componentContent, index, isFallbackComponent));
  }

  function getComponentProperties(componentContent, index, isFallbackComponent) {
    if (!componentContent || typeof componentContent !== 'object') {
      return {};
    }

    const props = {};
    let propsContainer;
    if (isFallbackComponent) {
      props.data = {};
      propsContainer = props.data;
    } else {
      propsContainer = props;
    }
    props.metadata = { '@index': index };
    props.key = buildKey(componentContent);

    Object.keys(componentContent).forEach(key => {
      if (key.match(/^(@|mgnl:|jcr:)/)) {
        props.metadata[key] = componentContent[key];
      } else {
        propsContainer[key.replace('-', '_')] = componentContent[key];
      }
    });
    return props;
  }

  function addComment(element, openComment, closeComment) {
    if (typeof document === 'undefined' || !element) {
      return;
    }
    if (openComment) {
      const openCommentElement = document.createComment(openComment);
      element.insertBefore(openCommentElement, element.firstChild);
    }
    if (closeComment) {
      const closeCommentElement = document.createComment(closeComment);
      element.appendChild(closeCommentElement);
    }
  }

  function classnames(...arg) {
    const classes = [];
    arg.forEach(item => {
      if (item == null) {
        return;
      }
      const itemType = typeof item;
      if (itemType === 'string' || itemType === 'number') {
        classes.push(item);
      } else if (Array.isArray(item) && item.length) {
        classes.push(classnames(...item));
      } else if (itemType === 'object') {
        Object.keys(item).forEach(key => {
          if (item[key]) {
            classes.push(key);
          }
        });
      }
    });
    return classes.join(' ');
  }

  function removeCommentElement(element) {
    if (element && element.nodeType === 8) {
      element.remove();
    }
  }

  function getFallbackComponent(fallbackComponentConfig) {
    const fallbackComponent = typeof fallbackComponentConfig === 'function' ? fallbackComponentConfig() : fallbackComponentConfig;
    return typeof fallbackComponent === 'function' ? fallbackComponent : fallbackComponent ? DefaultFallbackComponent : null;
  }

  function buildKey(componentContent) {
    return componentContent['@name'];
  }
}

export default componentHelper();
