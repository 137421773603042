import React from 'react';
import PropTypes from 'prop-types';
import { EditorContextHelper } from '../../util';

export default class EditableComment extends React.PureComponent {
  static propTypes = {
    // eslint-disable-next-line react/require-default-props
    annotation: PropTypes.string,
    callback: PropTypes.func,
    alwaysRender: PropTypes.bool
  };

  static defaultProps = {
    callback: undefined,
    alwaysRender: false
  }

  constructor() {
    super();

    this._renderedAnnotation = '';
    this.commentRef = React.createRef();
    this.mountedRef = React.createRef();

    this.ref = (node) => {
      this.renderAnnotation(node);
    };

    this.state = {
      render: false
    };
  }

  componentDidMount() {
    this.mountedRef.current = true;
    if (this.props.alwaysRender && this.props.annotation) {
      this.setState({ render: this.props.alwaysRender });
    } else {
      EditorContextHelper.inEditorAsync().then(inEditor => {
        // NOTE:
        // This mountedRef variable is for ensuring component is mounted before set state.
        if (this.mountedRef.current && inEditor) {
          this.setState({ render: inEditor });
        }
      });
    }
  }

  componentWillUnmount() {
    this.mountedRef.current = false;
      // NOTE:
      // #renderAnnotation method that it adds custom HTML comment by document API (not react framework),
      // so we have to remove that HTML comment before the component is unmounted and lost reference to the comment object.
      this.removeAnnotation();
  }

  renderAnnotation(element) {
    if (element) {
      this.removeAnnotation();

      this.commentRef.current = document.createComment(this._renderedAnnotation);
      element.before(this.commentRef.current);
      this.updateAnnotation();
      this.setState({ render: false });
    }
  }

  removeAnnotation() {
    if (this.commentRef.current && this.commentRef.current.parentNode) {
      this.commentRef.current.parentNode.removeChild(this.commentRef.current);
      this.commentRef.current = null;
    }
  }

  updateAnnotation() {
    const annotation = this.props.annotation || '';

    if (this.commentRef.current && this._renderedAnnotation !== annotation) {
      this.commentRef.current.textContent = annotation;
      this._renderedAnnotation = annotation;
      if (typeof this.props.callback === 'function') {
        setTimeout(() => this.props.callback(), 0);
      }
    }
  }

  render() {
    this.updateAnnotation();
    return this.state.render ? (<div ref={this.ref} />) : null;
  }
}
