import React from 'react';
import PropTypes from 'prop-types';
import { TemplateAnnotations } from '@magnolia/template-annotations';
import { EditableComponent } from '../EditableComponent';
import { EditableComment } from '../EditableComment';
import {
  EditorContext, constants, EditorContextHelper, ComponentHelper
} from '../../util';

class EditableArea extends React.PureComponent {
  static propTypes = {
    content: PropTypes.object.isRequired,
    parentTemplateId: PropTypes.string,
    className: PropTypes.any,
    elementType: PropTypes.any,
    customView: PropTypes.elementType,
    children: PropTypes.node
  };

  static defaultProps = {
    parentTemplateId: null,
    className: null,
    elementType: 'div',
    customView: null,
    children: null
  }

  constructor(props) {
    super(props);
    this.constants = constants;
  }

  componentDidMount() {
    if (!this.context) {
      throw new Error('EditableArea component must be wrapped inside EditablePage component.');
    }
  }

  static contextType = EditorContext;

  getParentTemplateId() {
    const { parentTemplateId } = this.props;
    const { content } = this.context;

    return parentTemplateId || content[constants.TEMPLATE_ID_PROP];
  }

  getAnnotation() {
    const { content } = this.props;
    if (!content) {
      return '';
    }
    const pageTemplateId = this.getParentTemplateId();
    const { templateDefinitions, templateAnnotations } = this.context;
    if (templateDefinitions && templateDefinitions[pageTemplateId]) {
      return TemplateAnnotations.getAreaCommentString(content, templateDefinitions[pageTemplateId]);
    }
    if (templateAnnotations && templateAnnotations[content?.['@path']]) {
      return templateAnnotations[content?.['@path']];
    }
    return '';
  }

  // eslint-disable-next-line class-methods-use-this
  onReady() {
    EditorContextHelper.refresh();
  }

  render() {
    const {
      content, className, elementType, children, customView: CustomView
    } = this.props;

    if (!content) {
      return null;
    }

    const componentContents = content['@nodes'].map(nodeName => content[nodeName]);
    const element = React.createElement(elementType || 'div', className ? { className: ComponentHelper.classnames(className) } : {});
    let childContent;
    if (CustomView) {
      childContent = <CustomView content={content} />;
    } else if (children) {
      childContent = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { content });
        }
        return child;
      });
    } else {
      childContent = componentContents.map((item, index) => (
        <EditableComponent key={ComponentHelper.buildKey(item)} content={item} index={index} />
      ));
    }
    return (
      <>
        <EditableComment annotation={this.getAnnotation()} callback={this.onReady} />
        <element.type key={ComponentHelper.buildKey(content)} {...element.props}>
          {childContent}
        </element.type>
        <EditableComment annotation={this.constants.CLOSED_AREA_COMMENT} />
      </>
    );
  }
}

export default EditableArea;
